<template>
  <v-row
    id="facture"
    class="app-invoice-preview"
  >
    <v-col
      cols="12"
      md="9"
    >
      <v-card
        id="page"
        class="page"
      >
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-7 mb-sm-0">
<!--              <div class="d-flex align-center mb-6">-->
<!--                <v-img-->
<!--                  :src="appLogo"-->
<!--                  max-height="30px"-->
<!--                  max-width="30px"-->
<!--                  alt="logo"-->
<!--                  contain-->
<!--                  class="me-3 "-->
<!--                ></v-img>-->
<!--                <span class="text&#45;&#45;primary font-weight-bold text-xl">-->
<!--                  {{ appName }}-->
<!--                </span>-->
<!--              </div>-->
              <img
                alt="logo"
                class="me-2"
                contain
                height="100px"
                width="100px"
                :src="logoGrossiste"
              />
              <span class="d-block text-bold text-uppercase">{{ clientInfo.short_app_name }}</span>
              <span class="d-block text-bold">N° Cont. {{ clientInfo.gs_contribuable }}</span>
              <span class="d-block text-bold">BP.:{{ clientInfo.gs_bp }}</span>
              <span class="d-block text-bold">{{ clientInfo.gs_pays }}</span>
              <span
                class="d-block text-bold"
              >Tel.: {{ clientInfo.gs_tel }}</span>
            </div>
            <!-- Right Content -->
            <div class="align-center">
              <p class="font-weight-medium text-xl text--primary mb-4">
                {{ appName }}
              </p>
<!--              :style="{ 'background-image': 'url(' + dfinSignature + ')' }"-->
              <img
                alt="logo"
                class="me-2"
                contain
                height="100px"
                width="100px"
                :src="appLogo"
              />
            </div>
          </div>
          <div class="text-center mt-8 mb-4">
            <h1 class="mb-4 text-decoration-none">
              {{ titre }}
            </h1>
          </div>
          <div class="text-center mt-8 mb-4">
            <h1 class="mb-4 text-decoration-underline">
              OBJET : PAIEMENT DE LA FACTURE DE DEPOSIT
            </h1>
            <!--            <h2>FACTURE N° BIZ1645D062022</h2>-->
          </div>
          <div
            v-if="isProforma"
            class="invoice-header d-flex flex-wrap justify-end flex-column flex-sm-row"
          >
            <div class="mb-7 mb-sm-0">
              <span class="d-block text-bold">DOIT</span>
              <span class="d-block text-bold">{{ customer.name }}</span>
              <span class="d-block text-bold">{{ customer.tel }}</span>
              <span class="d-block text-bold">{{ customer.email }}</span>
<!--              <span class="d-block text-bold">{{ clientInfo.gs_rue }}</span>-->
            </div>
          </div>
          <div
            v-else
            class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row"
          >
            <barcode
              :value="ticket"
              width="1"
              height="50"
            ></barcode>
            <div class="mb-7 mb-sm-0">
              <span class="d-block text-bold">{{ clientInfo.short_app_name }}</span>
              <span class="d-block text-bold">{{ clientInfo.gs_tel }}</span>
              <span class="d-block text-bold">{{ clientInfo.gs_rue }}</span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="bordure"
                  colspan="3"
                >
                  Désignation
                </th>
                <th class="bordure bordure-droit">
                  Montant HT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in purchasedProducts"
                :key="item.name"
              >
                <td
                  class="text-no-wrap bordure"
                  colspan="3"
                >
                  {{ item.description }}
                </td>
                <td class="text-left bordure bordure-droit">
                  {{ withCurrency(Math.ceil(netPayable-((netPayable*clientInfo.tva)/100))) }}
                </td>
              </tr>
              <tr class="border-0">
                <td class="text-right font-italic text-body-1">
                  Dévise
                </td>
                <td>
                  <b class="text-h6 font-weight-bold">{{ currency }}</b>
                </td>
                <td class="text-right font-weight-bold text-body-1">
                  Total HT :<v-spacer></v-spacer>
                  <span class="font-weight-semibold">TVA </span>:<v-spacer></v-spacer>
<!--                  <span class="font-weight-semibold">Discount:</span><v-spacer></v-spacer>-->
                </td>
                <td class="text-left">
                  {{ withCurrency(Math.ceil(netPayable-((netPayable*clientInfo.tva)/100))) }}<v-spacer></v-spacer>
                  {{ clientInfo.tva }}<v-spacer></v-spacer>
<!--                  {{ discount }}%-->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->

        <v-row class="px-6 py-2 border-light">
          <v-col
            cols="8"
            offset="4"
          >
            <v-divider></v-divider>
            <v-row class="py-2">
              <v-col
                cols="7"
                class="text-right"
              >
                <h3 class="text-uppercase">
                  Montant TTC :
                </h3>
              </v-col>
              <v-col
                cols="4"
                class="text-left"
              >
                <span class="text-uppercase body-1 success--text">{{ withCurrency(netPayable) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-text class="py-6 px-8  page-suivante">
          <div class="text-right pt-3">
            <h4>Facture éditée le {{ signedDate }}</h4>
            <img
              class="me-2"
              width="150px"
              :src="dfinSignature"
            />
            <img
              class="ml-n10"
              width="150px"
              :src="dfinCachet"
            />
            <br>
            <h4>Les Finances</h4>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 pt-4">
        <h1 class="text-center mb-8">
          Annexe justificatif
        </h1>
        <!-- Table -->
        <v-simple-table class="purchased-items-table mb-12">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="bordure">
                N°
              </th>
              <th class="bordure">
                Local
              </th>
              <th class="bordure">
                Emplacement - N° décodeur
              </th>
              <th class="bordure">
                Formule
              </th>
              <th class="bordure">
                Option
              </th>
              <th class="bordure bordure-droit">
                Montant
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in decoders"
              :key="item.number"
              v-if="item.formula.pu !== undefined "
            >
              <td class="text-no-wrap bordure">
                {{ decoders.indexOf(item)+1 }}
              </td>
              <td class="bordure">
                {{ item.loaclName }}
              </td>
              <td class="bordure">
                {{ item.location }} - {{ item.number }}
              </td>
              <td class="bordure">
                {{ item.formula.label }}
              </td>
              <td class="bordure">
                {{ item.option !== null?item.option.nom: "N/A" }}
              </td>
              <td class="text-right bordure bordure-droit">
                {{ item.option !== null?withCurrency(parseInt(item.option.pu) + parseInt(item.formula.pu)): withCurrency(item.formula.pu) }}
              </td>
            </tr>
<!--            <tr>-->
<!--              <td>-->
<!--                Devise-->
<!--              </td>-->
<!--              <td>-->
<!--                <h2>XAF</h2>-->
<!--              </td>-->
<!--              <td-->
<!--                colspan="3"-->
<!--                class="text-right"-->
<!--              >-->
<!--                Montant à payer-->
<!--              </td>-->
<!--              <td class="bordure bordure-droit text-right">-->
<!--                2180000-->
<!--              </td>-->
<!--            </tr>-->
            </tbody>
          </template>
        </v-simple-table>

        <br>

        <p class="mb-0 footer">
          Document généré par SYGALIN le {{ moment().format('LL à HH:m:s') }}
        </p>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      id="dont-see"
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            color="primary"
            class="mb-3"
            block
            :disabled="payBtn"
            @click="dialog=true"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Payer</span>
          </v-btn>
          <!--          <v-btn-->
          <!--            class="mb-3"-->
          <!--            color="secondary"-->
          <!--            block-->
          <!--            outlined-->
          <!--            :disabled="downloadBtn"-->
          <!--            @click="downloadPDF"-->
          <!--          >-->
          <!--            Télécharger-->
          <!--          </v-btn>-->
          <v-btn
            class="mb-3"
            color="primary"
            block
            outlined
            :disabled="printBtn"
            @click="printInvoice"
          >
            <v-icon
              class="me-2"
              left
            >
              fas fa-print
            </v-icon>
             Imprimer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <pay-form
        :montant="netPayable"
        @finish="dialog=false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import {
  ref, onUnmounted, onBeforeMount, computed,
} from '@vue/composition-api'
import store from '@/store'
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import VueBarcode from 'vue-barcode'
// eslint-disable-next-line import/extensions
import PayForm from '@/views/caution/PayForm'
import themeConfig from '@themeConfig'
import Http from '@/helpers/http'
import { CACHET_DFIN_NAME, SIGNATURE_DFIN_NAME } from '@/helpers/constants/contrat'
import { useRouter } from '@core/utils'
import moment from 'moment'
import Vue from 'vue'

Vue.prototype.moment = moment

export default {
  name: 'CautionBill',
  components: {
    barcode: VueBarcode, PayForm,
  },
  setup() {
    const paymentDetails = ref({})

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)
    const clientInfo = ref({})
    const bond = ref({})
    const amount = ref(0)
    const additionAmount = ref({})
    const loading = ref(true)
    const coef = ref(0)
    const discount = ref(0)
    const netPayable = ref(0)
    const amountToPay = ref(0)
    const dialog = ref(false)
    const page = ref(null)
    const customer = JSON.parse(localStorage.getItem('customer'))

    console.log('customer',customer)

    const { route } = useRouter()

    const ticket = ref(0)

    const dfinSignature = ref('')
    const dfinCachet = ref('')
    const logoGrossiste = ref('')

    const setCachet = () => {
      const params = { path: `signatures-grossistes/sygalin/${CACHET_DFIN_NAME}` }
      Http.post('getFileUrl', params)
        .then(response => {
          dfinCachet.value = response
        })
        .catch(error => error)
    }

    const setSignature = () => {
      const params = { path: `signatures-grossistes/sygalin/${SIGNATURE_DFIN_NAME}` }
      Http.post('getFileUrl', params)
        .then(response => {
          dfinSignature.value = response
        })
        .catch(error => error)
    }

    const setlogo= () => {
      const params = { path: `sygalin/logo/707/logo.png` }
      Http.post('getFileUrl', params)
        .then(response => {
          logoGrossiste.value = response
        })
        .catch(error => error)
    }

    const getClientInfo = () => {
      Http.get(`get-info-from-wholesaler/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          // eslint-disable-next-line prefer-destructuring
          clientInfo.value = response.data[0]
          clientInfo.value.tva = 19.25
          ticket.value = route.value.params.numero
          loading.value = false
        })
        .catch(error => {
          console.log(error)
          loading.value = false
        })
    }
    const payBtn = ref(false)
    const printBtn = ref(false)
    const downloadBtn = ref(false)

    const hasStartedGeneration = () => {
      payBtn.value = true
      printBtn.value = true
      downloadBtn.value = true
    }

    const hasGenerated = () => {
      payBtn.value = false
      printBtn.value = false
      downloadBtn.value = false
    }

    const getDeposit = () => {
      setCachet()
      setSignature()
      setlogo()
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data

            bond.value.amountBond = Math.ceil(bond.value.amountBond)
            bond.value.netPayable = Math.ceil(bond.value.netPayable)
            coef.value = bond.value.coeficientBond
            discount.value = bond.value.bonusActivationParameters
            netPayable.value = Math.ceil(bond.value.netPayable)
            amount.value = Math.ceil(bond.value.amount)
            amountToPay.value = Math.ceil(bond.value.amount)

            console.log('caution info',response.data)

            if (((bond.value.depositAlreadyPaidPending !== bond.value.netPayable) && bond.value.netPayable !== 0)) {
              const montant = Math.ceil(Math.abs(bond.value.coeficientBond - bond.value.amountBondPayed))
              const montantCoef = montant * parseFloat(bond.value.coeficientBond)
              const montantNet = Math.abs(montantCoef - ((montantCoef * bond.value.bonusActivationParameters) / 100))
              additionAmount.value = {
                amount: montant,
                coeficientAmount: montantCoef,
                netPayable: Math.ceil(montantNet),
              }
              amount.value = Math.ceil(additionAmount.value.netPayable)
              console.log('additionAmount.value.amount')
              amountToPay.value = additionAmount.value.amount
            }

            let decs = []

            for(let i=0;i<bond.value.locals.length;i++){
              let local = Object.entries(bond.value.locals[i].decoders).map(arr => ({
                loaclName: bond.value.locals[i].name,
                number: arr[1].number,
                formula: arr[1].formula,
                location: arr[1].location,
                option: arr[1].option,
              }))

              for(let j=0;j<local.length;j++){
                decs.push(local[j])
              }
            }

            decoders.value = decs

            console.log('decs',decs);
          }
          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const filename = computed(() => `PROFORMA_${moment().format('DD-MM-YYYY')}_BOND.pdf`)

    const purchasedProducts = [
      {
        id: 1,
        description: 'Paiement du déposit',
        price: 0,
      },
    ]

    const titre = ref('')
    const isProforma = ref(false)
    const signedDate = ref('')
    const decoders = ref('')

    const formatAmount = price =>
      // eslint-disable-next-line no-restricted-globals,implicit-arrow-linebreak
      `${Number(price).toLocaleString()}`

    // const formatAmount = unit => Number(unit).toLocaleString()

    onBeforeMount(() => {
      if (route.value.params.numero !== undefined) titre.value = 'Facture'
      else {
        titre.value = 'FACTURE PRO FORMAT'
        isProforma.value = true
        signedDate.value = moment().format('DD-MM-YYYY')
        getDeposit()
      }

      getClientInfo()

    })

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————
    const currency = ref('XAF')

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const withCurrency = price => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(price)) {
        return 0 + currency.value
      }

      return `${Number(price).toLocaleString()} ${currency.value}`
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const printInvoice = () => {
      // eslint-disable-next-line no-global-assign
      window.print()
    }

    return {
      paymentDetails,
      purchasedProducts,
      titre,
      amountToPay,
      signedDate,
      filename,
      customer,

      // invoiceDescription,
      printInvoice,
      getDeposit,
      bond,
      amount,
      additionAmount,
      loading,
      dfinCachet,
      dfinSignature,
      logoGrossiste,
      dialog,
      page,
      withCurrency,
      decoders,

      currency,
      clientInfo,
      getClientInfo,
      ticket,
      coef,
      discount,
      netPayable,
      isProforma,

      payBtn,
      printBtn,
      downloadBtn,

      hasStartedGeneration,
      hasGenerated,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,
      formatAmount,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
//@import '~@core/preset/preset/apps/invoice.scss';

.bordure{
  border-left:1px solid #a8a5a5;
  border-bottom:1px solid #a8a5a5;

}
.bordure-droit{
  border-right: 1px solid #a8a5a5;
}

th.bordure{
  background: #e1dede !important;
}

@media screen {
  .footer {
    display: none;
  }
}

@media print {

  @page {
    size: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .page{
    margin-bottom: 100px !important;
  }
  .v-application {
    background: none !important;
  }

  #dont-see{
    display: none !important;
  }

  .footer {
    position: fixed;
    bottom: 0;
    margin-top: 100px !important;
  }

  //.page-suivante {
  //  page-break-after: always;
  //  bottom: 0;
  //}

  .app-navigation-menu,
  .marquee-text,
  .v-app-bar,
  .v-footer,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .vert{
    color: #56ca00 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
